import React from "react";

const frases = [
  { quote: "“Wake up, Neo”", author: " " },
  {
    quote: "“The Universe is under no obligation to make sense to you.”",
    author: "-Neil deGrasse Tyson"
  },
  {
    quote:
      "“I would rather have questions that can't be answered than answers that can't be questioned.”",
    author: "-Richard Feynman"
  },
  {
    quote: "“Have no fear of perfection - you'll never reach it.”",
    author: "-Salvador Dali"
  },
  {
    quote:
      "“Design can be art. Design can be aesthetics. Design is so simple, that's why it is so complicated.”",
    author: "-Paul Rand"
  },
  {
    quote:
      "“Intelligence is an accident of evolution, and not necessarily an advantage.”",
    author: "-Isaac Asimov"
  },
  {
    quote:
      "“I hope you make mistakes. Because if you are making mistakes...you're Doing Something”",
    author: "-Neil Gaiman"
  },
  {
    quote: "“So much universe, and so little time.”",
    author: "-Terry Pratchett"
  }
];

class Home extends React.Component {
  state = {
    text: ""
  };
  newPhrase = index => {
    let texto = frases[index].quote;
    let haste = 70;
    setTimeout(() => {
      let newIndex;
      do {
        newIndex = Math.round(Math.random() * (frases.length - 1));
      } while (newIndex == index);
      this.setState({ className: "" });

      this.newPhrase(newIndex);
    }, (texto.length * 2 + 15) * haste);

    setTimeout(() => {
      this.setState({ author: frases[index].author, className: "on" });
    }, texto.length * haste);

    for (let i = 0; i < texto.length; i++) {
      setTimeout(
        () => this.setState({ text: texto.substr(0, ++aux) }),
        i * haste
      );
    }

    for (let i = texto.length + 14; i < texto.length * 2 + 15; i++) {
      setTimeout(
        () => this.setState({ text: texto.substr(0, --aux) }),
        i * haste
      );
    }

    let aux = 0;
  };
  componentDidMount() {
    this.newPhrase(0);
  }
  render() {
    return (
      <section className="hero">
        <article className="textos">
          <h4 id="phraseContainer">{this.state.text}</h4>
          <p id="author" className={this.state.className}>
            {this.state.author}
          </p>
        </article>
      </section>
    );
  }
}

export default Home;
