import React from "react";

const Portfolio = () => (
  <div>
    <ul class="portfolio">
      <li>
        <a href="https://xingyiquan.es/" target="_blank">
          <span class="titulo">Escuela Xing Yi Quan</span>
          <span class="description">Design and Development (WordPress)</span>
        </a>
      </li>
      <li>
        <a href="https://peluqueriajade.es/" target="_blank">
          <span class="titulo">Peluquería Jade</span>
          <span class="description">
            Branding, Design, Development (WordPress)
          </span>
        </a>
      </li>
      <li>
        <a href="http://obrascarpa.es/" target="_blank" rel="noopener">
          <span class="titulo">Obras Carpa Reformas</span>
          <span class="description">Design and Development (WordPress)</span>
        </a>
      </li>
      <li>
        <a href="http://masexpobooth.com/" target="_blank" rel="noopener">
          <span class="titulo">MasExpoBooth Stands Publicitarios</span>
          <span class="description">Design and Development (WordPress)</span>
        </a>
      </li>
      <li>
        <a
          href="https://escuelasinfantilesgarden.es/"
          target="_blank"
          rel="noopener"
        >
          <span class="titulo">Escuelas Infantiles Garden</span>
          <span class="description">Design and Development (WordPress)</span>
        </a>
      </li>
      <li>
        <a href="http://www.madrimudanzas.com/" target="_blank" rel="noopener">
          <span class="titulo">Madrileña Mudanzas</span>
          <span class="description">
            Design and Development in a team (WordPress)
          </span>
        </a>
      </li>
      <li>
        <a href="http://artdecar.es/" target="_blank" rel="noopener">
          <span class="titulo">Art d Car</span>
          <span class="description">Design and Development (WordPress)</span>
        </a>
      </li>
      <li>
        <a href="http://cartonajeslanka.com/" target="_blank" rel="noopener">
          <span class="titulo">Cartonajes Lanka</span>
          <span class="description">Design and Development (WordPress)</span>
        </a>
      </li>
      <li>
        <a href="http://mueblescamacoca.es/" target="_blank" rel="noopener">
          <span class="titulo">Muebles a Medida</span>
          <span class="description">
            Design including Logo, and Development (WordPress)
          </span>
        </a>
      </li>
      <li>
        <a href="http://gruposava.es/" target="_blank" rel="noopener">
          <span class="titulo">Sava Facility Service</span>
          <span class="description">Design and Development (WordPress)</span>
        </a>
      </li>
      <li>
        <a href="http://asisacoslada.es/" target="_blank" rel="noopener">
          <span class="titulo">Asisa Coslada</span>
          <span class="description">Design and Development (WordPress)</span>
        </a>
      </li>
      <li>
        <a href="https://www.misterchuches.com/" target="_blank" rel="noopener">
          <span class="titulo">Mister Chuches</span>
          <span class="description">
            Design and Development in a team (Prestashop)
          </span>
        </a>
      </li>
    </ul>
  </div>
);

export default Portfolio;
