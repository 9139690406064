import React from "react";
import Cmp from "../styles/img/cmp.jpg";

const About = () => (
  <section class="about">
    <article>
      <h5>
        Carlota Martínez Prentice is an illustrator and web designer based in
        Madrid.
      </h5>
      <p>
        She has always had a taste for art and nature, but also for the beauty
        found in logic and design. She has experienced a variety of studies like
        Comic Book Art, English Language and Literature, Math and Technical
        Drawing, Pottery, Special Effects Makeup…
      </p>
      <p>
        But everything finally led to specializing in{" "}
        <strong>Illustration</strong> at{" "}
        <a href="https://artediez.es/" target="_blank" rel="noopener">
          Escuela de Arte número Diez
        </a>{" "}
        in Madrid; and Graphic Design in{" "}
        <a
          href="https://www.gbmc.ac.uk/brighton"
          target="_blank"
          rel="noopener"
        >
          City College in Brighton
        </a>
        . Since 2010 she has been working as a freelance illustrator and graphic
        designer.
      </p>
      <p>
        After seeing the paper-based industry becoming out-dated, she decided to
        make the incursion onto a next level: Web Design and Web development.
        She took her first steps in{" "}
        <a href="https://www.cice.es/" target="_blank" rel="noopener">
          Cice Escuela de Formación
        </a>
        .
      </p>
      <p>
        Having worked for several agencies like{" "}
        <a href="http://providersweb.es/" target="_blank" rel="noopener">
          Web Providers
        </a>
        , where she developed her skills, in early 2018 she decided to go
        freelance and continue her journey on UX and web development.
      </p>
      <p>
        She found a good position in a team at{" "}
        <a href="https://www.rtve.es/" target="_blank" rel="noopener">
          RTVE
        </a>{" "}
        as web developer, doing maintenance of the site and adapting the old
        content to new technologies.
      </p>
      <p>
        Among personal interests, it’s only natural that she is into drawing,
        reading, sci-fi series, video games and martial arts.
      </p>
      <p>
        She also thinks that writing about herself in third person is quite
        awkward.
      </p>
      <quote>
        My key to creativity is to stay curious and find ways to adapt to new
        technologies.
      </quote>
    </article>
    <div class="circle">
      <img src={Cmp} title="Carlota Martinez Prentice" />
    </div>
  </section>
);

export default About;
