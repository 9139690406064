import React from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "../logo.svg";

const Header = () => (
  <header>
    <h1>
      <Link to="/" exact={true}>
        <img class="logo" src={Logo} />
        Cmprentice
      </Link>
    </h1>
    <nav>
      <ul>
        <li>
          <NavLink to="/" activeclassName="is-active" exact={true}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/portfolio" activeclassName="is-active" exact={true}>
            Portfolio
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" activeclassName="is-active" exact={true}>
            About
          </NavLink>
        </li>
      </ul>
    </nav>
  </header>
);

export default Header;
